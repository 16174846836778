import TransactionItemIcon from "@mui/icons-material/CurrencyExchange";
import TransactionItemCreate from "./TransactionItemCreate";
import TransactionItemEdit from "./TransactionItemEdit";
import TransactionItemList from "./TransactionItemList";
import TransactionItemShow from "./TransactionItemShow";

export default {
  name: "transaction-item",
  recordRepresentation: "name",
  list: TransactionItemList,
  create: TransactionItemCreate,
  edit: TransactionItemEdit,
  show: TransactionItemShow,
  icon: TransactionItemIcon,
};
