import { Card, Stack, useMediaQuery } from '@mui/material';
import { AutocompleteArrayInput, BooleanField, BooleanInput, Create, Datagrid, DeleteWithConfirmButton, Edit, EditButton, FunctionField, Labeled, List, Loading, ReferenceArrayInput, ReferenceField, ReferenceInput, Resource, SaveButton, SelectInput, SimpleForm, SimpleList, TextField, TextInput, Title, Toolbar, useCreate, useGetOne, useNotify, useRecordContext, useRedirect } from "react-admin";
import { useParams } from 'react-router-dom';
import Icon from '@mui/icons-material/Store';
import { useFormContext } from 'react-hook-form';
import EstablishmentTitle from './EstablishmentTitle';

const EstablishmentEdit = () => {
    const [create] = useCreate();

    return (
        <Edit title={<EstablishmentTitle />}>
            <SimpleForm sx={{ mb: 10 }}>
                <TextInput disabled source="id" />
                <ReferenceInput
                    label="Company"
                    source="company_id"
                    reference="company"
                    sort={{ field: 'id', order: 'ASC' }}>
                    <SelectInput />
                </ReferenceInput>
                <TextInput required source="name" />
                <BooleanInput source="isActive" />
                <ReferenceArrayInput source="suggestedTags" reference="tag">
                    <AutocompleteArrayInput
                        fullWidth
                        source="suggestedTags"
                        onCreate={(value: any) => {
                            create(
                                'tag',
                                {
                                    data: {
                                        name: value,
                                        isActive: true,
                                    },
                                },
                                {
                                    onSuccess: ({ data }) => {
                                        console.log('result', data);
                                    },
                                }
                            );
                            return value;
                        }}
                    />
                </ReferenceArrayInput>
            </SimpleForm>
        </Edit>
    );
};

export default EstablishmentEdit;