import { Create, DateTimeInput, NumberInput, SimpleForm } from "react-admin";
import TransactionItemToolbar from './TransactionItemToolbar';

const TransactionItemCreate = (props: any) => {

    return (
        <Create {...props}>
            <SimpleForm toolbar={<TransactionItemToolbar />}>
                <DateTimeInput required source="datetime" />
                <NumberInput required source="amount" />
            </SimpleForm>
        </Create>
    );
}

export default TransactionItemCreate;