import {
    Chip
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useInput } from "react-admin";
import { useFormContext } from 'react-hook-form';
import dataProvider from '../../providers/dataProvider';

// const SuggestedTagsPanel = (props: { source: any, params: FormDataConsumerRenderParams }) => {
const SuggestedTagsPanel = (props: any) => {
    const [suggestedTagsData, setSuggestedTagsData] = useState<undefined | 'isLoading' | []>(undefined);
    const { setValue, formState, getValues, setError, clearErrors } = useFormContext();
    const input = useInput({ source: props.source });
    const mainEstablishmentInput = useInput({ source: 'mainEstablishment' });
    const establishmentInput = useInput({ source: `${input.field.name.replace('.tags', '')}.establishment` });
    const scopedFormDataEstablishment = establishmentInput.field.value;
    const mainEstablishment = mainEstablishmentInput.field.value;
    const selectedEstablishment = scopedFormDataEstablishment ? scopedFormDataEstablishment : mainEstablishment;

    useEffect(() => {
        if (suggestedTagsData !== 'isLoading') {
            dataProvider.getList('tag', {
                pagination: { page: 1, perPage: 100 },
                sort: { id: 'ASC' },
                filter: { isActive: true, establishments: [selectedEstablishment] }
            })
                .then((response: any) => {
                    if (response.data.length > 0) {
                        const tags: any = [];
                        response.data.map((t: any) => {
                            if (!getValues(input.field.name).includes(t.id)) {
                                tags.push(t);
                            }
                        });
                        setSuggestedTagsData(tags);
                    }
                })
                .catch((error: any) => {
                });
        }
    }, [selectedEstablishment]);

    return (
        <div>
            <>
                {suggestedTagsData !== undefined && suggestedTagsData !== 'isLoading' && suggestedTagsData.map((tag: any) => (
                    <Chip
                        onClick={() => {
                            setValue(input.field.name, [
                                ...getValues(input.field.name),
                                tag.id
                            ]);
                        }}
                        label={tag.name} />
                ))}
            </>
        </div>
    );
};

export default SuggestedTagsPanel;