import { ArrayInput, AutocompleteArrayInput, AutocompleteInput, FormDataConsumer, FormDataConsumerRenderParams, NumberField, NumberInput, ReferenceArrayInput, ReferenceInput, SimpleFormIterator, TextInput, useCreate } from "react-admin";
import SuggestedTagsPanel from "./SuggestedTagsPanel";

const TransactionItemsInput = (props: any) => {
    const [create] = useCreate();

    return (
        <ArrayInput {...props}
            label="Items" sx={{
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: 'red',
                borderRadius: 10,
                padding: 5,
            }}
            required
            fullWidth>
            <SimpleFormIterator fullWidth>
                <TextInput fullWidth source="name" />
                <ReferenceInput fullWidth source="establishment" reference="establishment">
                    <AutocompleteInput fullWidth />
                </ReferenceInput>
                <NumberInput defaultValue={1} onFocus={(e) => e.target.select()} required source="quantity" />
                <NumberInput required source="price" />
                <NumberInput fullWidth source="discount" />
                <FormDataConsumer>
                    {({ getSource, scopedFormData }) => {
                        if (!getSource) {
                            return <span>Something went wrong</span>;
                        }
                        return (
                            <NumberField
                                source="totalItemPrice"
                                options={{ style: 'currency', currency: 'BGN' }}
                                record={{ totalItemPrice: (scopedFormData.price * scopedFormData.quantity) - scopedFormData.discount }} />
                        );
                    }}
                </FormDataConsumer>
                <ReferenceArrayInput source="tags" reference="tag">
                    <AutocompleteArrayInput
                        fullWidth
                        onCreate={(value: any) => {
                            create(
                                'tag',
                                {
                                    data: {
                                        name: value,
                                        isActive: true,
                                    },
                                },
                                {
                                    onSuccess: ({ data }) => {
                                        console.log('result', data);
                                    },
                                }
                            );
                            return value;
                        }}
                    />
                </ReferenceArrayInput>
                <SuggestedTagsPanel source="tags" />
                {/* <FormDataConsumer>
                                    {(params: FormDataConsumerRenderParams) => {
                                        return <SuggestedTagsPanel source="tags" params={params} />;
                                    }}
                                </FormDataConsumer> */}
            </SimpleFormIterator>
        </ArrayInput>
    );
};

export default TransactionItemsInput;