import { useMediaQuery } from '@mui/material';
import { Datagrid, DateField, DeleteWithConfirmButton, EditButton, List, NumberField, SimpleList, TextField } from "react-admin";

const TransactionItemList = () => {
    const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

    if (isSmall) {
        return (
            <List>
                <SimpleList
                    primaryText={record => record.name}
                    secondaryText={record => (
                        <>
                            <DateField source="datetime" showTime={true} />
                            <NumberField source="amount" options={{ style: 'currency', currency: "BGN" }} />
                        </>
                    )}
                />
            </List>
        );
    }

    return (
        <List>
            <Datagrid>
                <TextField source="id" />
                <DateField source="datetime" showTime={true} />
                <NumberField source="amount" options={{ style: 'currency', currency: "BGN" }} />
                <EditButton />
                <DeleteWithConfirmButton />
            </Datagrid>
        </List>
    );
};

export default TransactionItemList;