import { DateField, NumberField, TextField, useRecordContext } from "react-admin";

const TransactionTitle = () => {
    const record = useRecordContext();
    return (
        <>
            <TextField source="mainEstablishment.name" record={record} />
            <span> - </span>
            <DateField source="timestamp" record={record} />
            <span> - </span>
            <NumberField source="totalAmount" record={record} options={{ style: 'currency', currency: 'BGN' }} />
        </>
    );
    return <span>{record ? `${new Date(record.timestamp).toLocaleDateString()} - <NumberField source="totalAmount"> ${record.totalAmount}` : ''}</span>;
};

export default TransactionTitle;