import { BooleanInput, Create, NumberInput, ReferenceInput, SelectInput, SimpleForm, TextInput } from "react-admin";
import PaymentAccountToolbar from './PaymentAccountToolbar';

const PaymentAccountCreate = (props: any) => {
    return (
        <Create {...props}>
            <SimpleForm sx={{ mb: 10 }} toolbar={<PaymentAccountToolbar />}>
                <TextInput required source="name" />
                <ReferenceInput reference="budget" source="budget">
                    <SelectInput />
                </ReferenceInput>
                <BooleanInput source="isActive" />
                <BooleanInput source="isDefault" />
                <TextInput source="type" />
                <NumberInput source="balance" />
            </SimpleForm>
        </Create>
    );
}

export default PaymentAccountCreate;