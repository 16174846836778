import * as React from "react";
import { Card, CardHeader, CardContent, Divider } from '@mui/material';
import {
    ResponsiveContainer,
    AreaChart,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Bar,
    Legend,
    BarChart,
} from 'recharts';
import { format, subDays, addDays } from 'date-fns';
import { AutocompleteArrayInput, useTranslate } from "react-admin";
import dataProvider from "./providers/dataProvider";
import MonthTagBarChart from "./components/MonthTagBarChart";
import EstablishmentBarChart from "./components/EstablishmentBarChart";

export interface Transaction {
    id: number;
    type: string;
    timestamp: Date;
    totalAmount: number;
    status: string;
}

interface TotalByDay {
    timestamp: number;
    totalAmount: number;
}

const lastDay = new Date();
const lastMonthDays = Array.from({ length: 30 }, (_, i) => subDays(lastDay, i));
const aMonthAgo = subDays(new Date(), 30);

const dateFormatter = (date: number): string =>
    new Date(date).toLocaleDateString();


const aggregateTransactionsByDay = (transactions: Transaction[]): { [key: string]: number } =>
    transactions
        .filter((transaction: Transaction) => transaction.type === 'expense')
        .reduce((acc, curr) => {
            const day = format(new Date(curr.timestamp), 'yyyy-MM-dd');
            if (!acc[day]) {
                acc[day] = 0;
            }
            acc[day] += curr.totalAmount * -1;
            return acc;
        }, {} as { [key: string]: number });

const getRevenuePerDay = (transactions: Transaction[]): TotalByDay[] => {
    const daysWithRevenue = aggregateTransactionsByDay(transactions);
    return lastMonthDays.map((date: Date) => ({
        timestamp: date.getTime(),
        totalAmount: daysWithRevenue[format(date, 'yyyy-MM-dd')] || 0,
    }));
};

const Dashboard = () => {
    const [transactions, setTransactions] = React.useState(null);

    React.useEffect(() => {
        if (transactions === null) {
            if (transactions !== 'isLoading') {
                dataProvider
                    .getList('transaction', {
                        pagination: {
                            page: 1,
                            perPage: 1000,
                        },
                        sort: {
                            field: 'id',
                            transaction: 'DESC',
                        },
                        filter: {}
                    })
                    .then((value: { data: any; total: any; }) => {
                        setTransactions(value.data);
                    })
                    .catch((error: any) => { });
            }
        }
    }, [transactions]);

    const translate = useTranslate();
    if (!transactions) return null;

    return (
        <>
            <Card>
                <CardHeader title={translate('pos.dashboard.month_history')} />
                <CardContent>
                    <div style={{ width: '100%', height: 300 }}>
                        <ResponsiveContainer>
                            <AreaChart data={getRevenuePerDay(transactions)}>
                                <defs>
                                    <linearGradient
                                        id="colorUv"
                                        x1="0"
                                        y1="0"
                                        x2="0"
                                        y2="1"
                                    >
                                        <stop
                                            offset="5%"
                                            stopColor="#8884d8"
                                            stopOpacity={0.8}
                                        />
                                        <stop
                                            offset="95%"
                                            stopColor="#8884d8"
                                            stopOpacity={0}
                                        />
                                    </linearGradient>
                                </defs>
                                <XAxis
                                    dataKey="timestamp"
                                    name="Date"
                                    type="number"
                                    scale="time"
                                    domain={[
                                        addDays(aMonthAgo, 1).getTime(),
                                        new Date().getTime(),
                                    ]}
                                    tickFormatter={dateFormatter}
                                />
                                <YAxis dataKey="totalAmount" name="Revenue" unit="лв." width={80} />
                                <CartesianGrid strokeDasharray="3 3" />
                                <Tooltip
                                    cursor={{ strokeDasharray: '3 3' }}
                                    formatter={(value: any) =>
                                        new Intl.NumberFormat(undefined, {
                                            style: 'currency',
                                            currency: 'BGN',
                                        }).format(value)
                                    }
                                    labelFormatter={(label: any) =>
                                        dateFormatter(label)
                                    }
                                />
                                <Area
                                    type="monotone"
                                    dataKey="totalAmount"
                                    stroke="#8884d8"
                                    strokeWidth={2}
                                    fill="url(#colorUv)"
                                />
                            </AreaChart>
                        </ResponsiveContainer>
                    </div>
                </CardContent>
            </Card>
            <Divider sx={{ my: 1 }} />
            <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
                <MonthTagBarChart />
                <EstablishmentBarChart />
            </div>
        </>
    )
};

export default Dashboard;