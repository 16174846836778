import { SaveButton, Toolbar, useNotify } from "react-admin";
import { useFormContext } from 'react-hook-form';

const TransactionItemToolbar = () => {
    const { reset } = useFormContext();
    const notify = useNotify();
    return (
        <Toolbar>
            <SaveButton label="Save" />
            <SaveButton
                label="Save and add"
                mutationOptions={{
                    onSuccess: () => {
                        notify('Element created');
                        reset();
                    }
                }
                }
                type="button"
                variant="text"
            />
        </Toolbar>
    );
};

export default TransactionItemToolbar;