import { BooleanInput, Create, SimpleForm, TextInput } from "react-admin";
import { ColorInput } from 'react-admin-color-picker';
import TagToolbar from './TagToolbar';

const TagCreate = (props: any) => {
    const iconProps = {
        icons: ['fipicon-angle-left', 'fipicon-angle-right', 'fipicon-angle-up', 'fipicon-angle-down'],
        theme: 'bluegrey',
        renderUsing: 'class',
        value: '',
        onChange: (value: string) => { },
        isMulti: false,
    };

    return (
        <Create {...props}>
            <SimpleForm sx={{ mb: 10 }} toolbar={<TagToolbar />}>
                <TextInput required source="name" />
                <BooleanInput source="isActive" />
                <ColorInput source="color" />
                <TextInput source="iconName" />
                <TextInput source="iconFont" />
            </SimpleForm>
        </Create>
    );
}

export default TagCreate;