import '@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.base-theme.react.css';
import '@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.material-theme.react.css';
import { BooleanInput, Create, NumberInput, SimpleForm, TextInput } from "react-admin";

const BudgetCreate = (props: any) => {
    return (
        <Create {...props}>
            <SimpleForm sx={{ mb: 10 }}>
                <TextInput fullWidth required source="name" />
                <BooleanInput fullWidth source="isActive" />
                <NumberInput fullWidth source="balance" options={{ style: 'currency', currency: 'BGN' }} />
            </SimpleForm>
        </Create>
    );
}

export default BudgetCreate;