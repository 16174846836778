import { AutocompleteArrayInput, BooleanInput, Create, ReferenceArrayInput, ReferenceInput, SelectInput, SimpleForm, TextInput, useCreate } from "react-admin";
import EstablishmentToolbar from './EstablishmentToolbar';

const EstablishmentCreate = (props: any) => {
    const [create] = useCreate();

    return (
        <Create {...props}>
            <SimpleForm sx={{ mb: 10 }} toolbar={<EstablishmentToolbar />}>
                <ReferenceInput
                    label="Company"
                    source="company_id"
                    reference="company"
                    sort={{ field: 'id', order: 'ASC' }}>
                    <SelectInput />
                </ReferenceInput>
                <TextInput required source="name" />
                <BooleanInput source="isActive" />
                <ReferenceArrayInput source="suggestedTags" reference="tag">
                    <AutocompleteArrayInput
                        fullWidth
                        onCreate={(value: any) => {
                            create(
                                'tag',
                                {
                                    data: {
                                        name: value,
                                        isActive: true,
                                    },
                                },
                                {
                                    onSuccess: ({ data }) => {
                                        console.log('result', data);
                                    },
                                }
                            );
                            return value;
                        }}
                    />
                </ReferenceArrayInput>
            </SimpleForm>
        </Create>
    );
}

export default EstablishmentCreate;