import '@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.base-theme.react.css';
import '@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.material-theme.react.css';
import { useMediaQuery } from '@mui/material';
import { BooleanField, Datagrid, DeleteWithConfirmButton, EditButton, FunctionField, List, NumberField, ReferenceArrayField, SimpleForm, SimpleList, SingleFieldList, TextField } from "react-admin";
import Icon from '@mui/icons-material/Wallet';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import Chip from '@mui/material/Chip';

const BudgetList = () => {
    const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

    if (isSmall) {
        return (
            <List>
                <SimpleList
                    leftAvatar={() => <Icon />}
                    primaryText={record => record.name}
                    rowStyle={(record, index) => ({ backgroundColor: index % 2 === 0 ? '#efe' : 'white' })}
                    tertiaryText={record => <BooleanField source="isActive" />}
                    secondaryText={record => (
                        <NumberField source="balance" options={{ style: 'currency', currency: 'BGN' }} />
                    )}
                />
            </List>
        );
    }

    return (
        <List>
            <Datagrid>
                <TextField source="id" />
                <TextField source="name" />
                <BooleanField source="isActive" />
                <NumberField source="balance" options={{ style: 'currency', currency: 'BGN' }} />
                <ReferenceArrayField reference="payment-account" source="paymentAccounts">
                    <SingleFieldList>
                        <FunctionField
                            sx={{ mx: 1 }}
                            render={(record: any) => <Chip
                                deleteIcon={record.isActive ? <DoneIcon /> : <CloseIcon />}
                                onDelete={() => { }}
                                label={`${record.name} ${record.balance}`} />} />
                    </SingleFieldList>
                </ReferenceArrayField>
                <EditButton />
                <DeleteWithConfirmButton />
            </Datagrid>
        </List>
    );
};

export default BudgetList;