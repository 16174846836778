import { DateTimeInput, Edit, NumberInput, SimpleForm, TextInput } from "react-admin";
import TransactionItemTitle from './TransactionItemTitle';

const TransactionItemEdit = () => (
    <Edit title={<TransactionItemTitle />}>
        <SimpleForm>
            <TextInput disabled source="id" />
            <DateTimeInput required source="datetime" />
            <NumberInput required source="amount" />
        </SimpleForm>
    </Edit>
);

export default TransactionItemEdit;