import '@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.base-theme.react.css';
import '@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.material-theme.react.css';
import MaleIcon from '@mui/icons-material/Man';
import FemaleIcon from '@mui/icons-material/Woman';
import { useMediaQuery } from '@mui/material';
import { Datagrid, DateField, DeleteWithConfirmButton, EditButton, List, SimpleList, TextField } from "react-admin";

const FamilyMemberList = () => {
    const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

    if (isSmall) {
        return (
            <List>
                <SimpleList
                    leftIcon={record => record.sex === 'male' ? <MaleIcon /> : <FemaleIcon />}
                    primaryText={record => `${record.firstName} ${record.middleName} ${record.lastName}`}
                    rowStyle={(record, index) => ({ backgroundColor: index % 2 === 0 ? '#efe' : 'white' })}
                    secondaryText={record => (
                        <DateField source="dateOfBirth" />
                    )}
                />
            </List>
        );
    }

    return (
        <List>
            <Datagrid>
                <TextField source="id" />
                <TextField source="firstName" />
                <TextField source="middleName" />
                <TextField source="lastName" />
                <DateField source="dateOfBirth" />
                <EditButton />
                <DeleteWithConfirmButton />
            </Datagrid>
        </List>
    );
};

export default FamilyMemberList;