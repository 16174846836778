import { Card, Stack, useMediaQuery } from '@mui/material';
import { AutocompleteArrayInput, BooleanField, BooleanInput, Create, Datagrid, DeleteWithConfirmButton, Edit, EditButton, FunctionField, Labeled, List, Loading, ReferenceArrayInput, ReferenceField, ReferenceInput, Resource, SaveButton, SelectInput, SimpleForm, SimpleList, TextField, TextInput, Title, Toolbar, useCreate, useGetOne, useNotify, useRecordContext, useRedirect } from "react-admin";
import { useParams } from 'react-router-dom';
import Icon from '@mui/icons-material/Store';
import { useFormContext } from 'react-hook-form';

const EstablishmentList = () => {
    const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

    if (isSmall) {
        return (
            <List>
                <SimpleList
                    leftAvatar={() => <Icon />}
                    primaryText={record => record.name}
                    rowStyle={(record, index) => ({ backgroundColor: index % 2 === 0 ? '#efe' : 'white' })}
                    tertiaryText={record => <BooleanField source="isActive" />}
                // secondaryText={record => (
                //     <TextField source="name" />
                // )}
                />
            </List>
        );
    }

    return (
        <List>
            <Datagrid>
                <TextField source="id" />
                <TextField source="company.name" />
                <TextField source="name" />
                <BooleanField source="isActive" />
                <EditButton />
                <DeleteWithConfirmButton />
            </Datagrid>
        </List>
    );
};

export default EstablishmentList;