import { Card, CardContent, CardHeader } from '@mui/material';
import { useEffect, useState } from 'react';
import { AutocompleteArrayInput, Button, Form, ReferenceArrayInput, useTranslate } from 'react-admin';
import {
    Bar,
    BarChart,
    CartesianGrid,
    Legend,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import dataProvider from '../providers/dataProvider';

const EstablishmentBarChart = () => {
    const translate = useTranslate();
    const dateRange = undefined;
    const [ids, setIds] = useState<number[] | null>(null);
    const [establishments, setEstablishments] = useState<{ id: number; name: string; }[] | null | 'isLoading'>(null);
    const [barCharData, setBarCharData] = useState(null);

    const getStats = (establishmentIds: number[]) => {

        const filter: {establishments: number[], dateRange?: {start: Date; end: Date}} = {
            establishments: establishmentIds,
        };
        if (dateRange) {
            filter.dateRange = dateRange;
        }

        dataProvider.getEstablishmentStats(filter)
            .then((value: { data: any, establishments: { id: number; name: string; }[] }) => {
                setEstablishments(value.establishments);
                setBarCharData(value.data);
            })
            .catch((error) => {
                console.log(error, 'error')
             });
    };

    useEffect(() => {
        if (establishments === null) {
            if (establishments !== 'isLoading') {
                if (ids) {
                    getStats(ids);
                }
            }
        }

        return;
    }, [ids]);

    if (establishments === undefined || establishments === null || establishments === 'isLoading' || establishments.length === 0) {
        return (
            <Card sx={{ display: 'flex', flex: 1, mr: 1, flexDirection: 'column' }}>
                <Form onSubmit={(data: any) => {
                    setEstablishments(null);
                    setIds(data.establishment);
                }}>
                    <ReferenceArrayInput source="establishment" reference="establishment">
                        <AutocompleteArrayInput source="establishment" />
                    </ReferenceArrayInput>
                    <Button type='submit' variant='contained' label='Apply' />
                </Form>
            </Card>
        );
    }

    console.log('barCharData123', barCharData)
    return (
        <Card sx={{ display: 'flex', flex: 1, mr: 1, flexDirection: 'column' }}>
            <Form onSubmit={(data: any) => {
                setEstablishments(null);
                setIds(data.establishment)
            }}>
                <ReferenceArrayInput source="establishment" reference="establishment">
                    <AutocompleteArrayInput source="establishment" />
                </ReferenceArrayInput>
                <Button type='submit' variant='contained' label='Apply' />
            </Form>
            <CardHeader title={translate('pos.dashboard.month_history_by_establishment')} />
            <CardContent>
                <div style={{ width: '100%', height: 300 }}>
                    <ResponsiveContainer width="100%" height="100%">
                        <BarChart
                            width={500}
                            height={300}
                            data={barCharData || []}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis dataKey="amount" />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="amount" unit="лв." />
                            {/* {establishments.map((e: any) => (
                                <Bar dataKey={e.amount} unit="лв." />
                            ))} */}
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </CardContent>
        </Card>
    );
};

export default EstablishmentBarChart;