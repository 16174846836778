import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from '../i18n/en';

const messages = {
    de: () => import('../i18n/de').then(messages => messages.default),
};

export default polyglotI18nProvider(locale => {
    if (locale === 'de') {
        return messages[locale]();
    }

    // Always fallback on english
    return englishMessages;
}, 'en');
