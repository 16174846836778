import TransactionIcon from "@mui/icons-material/CurrencyExchange";
import TransactionCreate from "./TransactionCreate";
import TransactionEdit from "./TransactionEdit";
import TransactionList from "./TransactionList";
import TransactionShow from "./TransactionShow";

export default {
  name: "transaction",
  recordRepresentation: "name",
  list: TransactionList,
  create: TransactionCreate,
  edit: TransactionEdit,
  show: TransactionShow,
  icon: TransactionIcon,
};
