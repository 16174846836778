import { Card, Stack } from '@mui/material';
import { BooleanField, EditButton, Labeled, Loading, NumberField, ReferenceField, TextField, Title, useGetOne, useRedirect } from "react-admin";
import { useParams } from 'react-router-dom';

const PaymentAccountShow = () => {
    const { id } = useParams();
    const redirect = useRedirect();
    const { data, isLoading } = useGetOne(
        'PaymentAccount',
        { id },
        { onError: () => redirect('/PaymentAccounts') }
    );
    if (isLoading) { return <Loading />; }
    return (
        <div>
            <Title title="Payment Account Show" />
            <Card>
                <Stack spacing={1}>
                    <EditButton />
                    <Labeled label="Name">
                        <TextField source="name" record={data} />
                    </Labeled>
                    <Labeled label="Budget">
                        <ReferenceField reference="budget" source="budget" record={data} />
                    </Labeled>
                    <Labeled label="isActive">
                        <BooleanField source="isActive" record={data} />
                    </Labeled>
                    <Labeled label="isDefault">
                        <BooleanField source="isDefault" record={data} />
                    </Labeled>
                    <Labeled label="Type">
                        <TextField source="Type" record={data} />
                    </Labeled>
                    <Labeled label="Balance">
                        <NumberField source="balance" options={{ style: 'currency', currency: 'BGN' }} record={data} />
                    </Labeled>
                    <EditButton />
                </Stack>
            </Card>
        </div>
    );
};

export default PaymentAccountShow;