import { useEffect } from "react";
import { NumberInput, TextField } from "react-admin";
import { useFormContext, useWatch } from 'react-hook-form';

const TotalInput = (props: any) => {
    const { setValue, formState, getValues, setError, clearErrors } = useFormContext();
    const transactionItems = useWatch({ name: 'transactionItems' });
    const transactionPaymentAccounts = useWatch({ name: 'transactionPaymentAccounts' });
    const discount = useWatch({ name: 'discount' });

    let totalAmount = 0;
    transactionItems.map((item: any) => {
        totalAmount += ((+item.quantity || 0) * (+item.price || 0)) - ((+item.quantity || 0) * (+item.discount || 0));
    });
    totalAmount -= discount || 0;

    let transactionPaymentAccountsTotal = 0;
    transactionPaymentAccounts.map((account: any) => {
        transactionPaymentAccountsTotal += account.amount;
    });

    useEffect(() => {
        setValue('totalAmount', totalAmount);

        if (totalAmount !== transactionPaymentAccountsTotal) {
            setError('InvalidTotal', {
                message: 'Платената сума не съвпада с цялата сума на транзакцията'
            });
        } else {
            clearErrors('InvalidTotal');
        }
    }, [totalAmount]);

    return (
        <>
            <TextField
                source="errorTotal"
                color='red'
                record={{
                    errorTotal: totalAmount !== transactionPaymentAccountsTotal ? 'Платената сума не съвпада с цялата сума на транзакцията' : ''
                }} />
            <NumberInput
                {...props}
                fullWidth
                error={totalAmount !== transactionPaymentAccountsTotal}
                sx={{
                    borderTop: 'solid 1px red'
                }}
                required
                options={{ style: 'currency', currency: 'BGN' }}
            />
        </>
    );
};

export default TotalInput;