import {
    Divider
} from '@mui/material';
import { AutocompleteInput, Create, DateTimeInput, ImageField, ImageInput, NumberInput, ReferenceInput, SelectInput, SimpleForm, TextField, required } from "react-admin";
import PaymentAccountInput from './PaymentAccountInput';
import TotalInput from './TotalInput';
import TransactionItemsInput from './TransactionItemsInput';
import { validateTransactionCreation } from '../../validators/validateUserCreation';
import {useForm} from 'react-hook-form';

const TransactionCreate = (props: any) => {

    const form = useForm();
    const formErrors = form.formState.errors;
    console.log('formErrors', formErrors)

    return (
        <Create
            {...props}
            redirect={'/transaction'}
            record={{
                transactionItems: [{}],
                transactionPaymentAccounts: [{}]
            }}>
            {/* <SimpleForm validate={validateTransactionCreation} sx={{ mb: 10 }}> */}
            <SimpleForm sx={{ mb: 10 }}>
                {/* {formErrors !== undefined && formErrors.map((error: any) => (
                    <TextField source="error" record={{error: error}} />
                ))} */}
                <ReferenceInput required source="mainEstablishment" reference="establishment">
                    <AutocompleteInput validate={required()} isRequired={true} fullWidth />
                </ReferenceInput>

                <ImageInput source="receipts" label="Receipts" multiple>
                    <ImageField source="src" title="title" />
                </ImageInput>

                <SelectInput required fullWidth source="type" defaultValue="expense" choices={[{ id: "income", name: 'Приход' }, { id: "expense", name: 'Разход' }]} />
                <DateTimeInput fullWidth defaultValue={new Date()} required source="timestamp" label="Дата на транзакцията" />

                <TransactionItemsInput source="transactionItems" />

                <PaymentAccountInput source="transactionPaymentAccounts" />

                <Divider sx={{ width: '100%', height: 2, mt: 3, mb: 5 }} />

                <NumberInput fullWidth source="discount" />
                <TotalInput source="totalAmount" />
            </SimpleForm>
        </Create>
    );
}

export default TransactionCreate;