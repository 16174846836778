import { useEffect } from 'react';
import { NumberInput, useInput } from "react-admin";
import { useFormContext, useWatch } from 'react-hook-form';

const PaymentAccountAmountInput = (props: any) => {

    const transactionItems = useWatch({ name: 'transactionItems' });
    const input = useInput({source: props.source});
    const transactionPaymentAccounts = useWatch({ name: 'transactionPaymentAccounts' });
    const { setValue } = useFormContext();

    let totalAmount = 0;
    if (transactionPaymentAccounts.length === 1) {
        transactionItems.map((item: any) => {
            totalAmount += ((+item.quantity || 0) * (+item.price || 0)) - (+item.discount || 0);
        });
    }

    useEffect(() => {
        if (transactionPaymentAccounts.length > 1) {
            transactionPaymentAccounts.map((item: any, index: number) => {
                if (index === 0) {
                    return;
                }
                setValue(input.field.name, 0);
            });
        }
    }, []);

    useEffect(() => {
        if (transactionPaymentAccounts.length === 1) {
            console.log(input)
            setValue(input.field.name, totalAmount);
        }
    }, [totalAmount]);

    return (
        <NumberInput {...props} />
    );
};

export default PaymentAccountAmountInput;