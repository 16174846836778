import '@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.base-theme.react.css';
import '@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.material-theme.react.css';
import { Create, DateInput, SimpleForm, TextInput } from "react-admin";

const FamilyCreate = (props: any) => {

    return (
        <Create {...props}>
            <SimpleForm sx={{ mb: 10 }}>
                <TextInput required source="name" />
                <DateInput required source="startDate" />
            </SimpleForm>
        </Create>
    );
}

export default FamilyCreate;