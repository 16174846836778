import '@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.base-theme.react.css';
import '@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.material-theme.react.css';
import { Card, Stack, useMediaQuery } from '@mui/material';
import { BooleanInput, Edit, NumberInput, SimpleForm, TextInput } from "react-admin";
import BudgetTitle from './BudgetTitle';

const BudgetEdit = () => (
    <Edit title={<BudgetTitle />}>
        <SimpleForm sx={{ mb: 10 }}>
            <TextInput fullWidth disabled source="id" />
            <TextInput fullWidth required source="name" />
            <BooleanInput fullWidth source="isActive" />
            <NumberInput fullWidth source="balance" options={{ style: 'currency', currency: 'BGN' }} />
        </SimpleForm>
    </Edit>
);

export default BudgetEdit;