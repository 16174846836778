import React, { useEffect, useState } from 'react';
import { Card, CardHeader, CardContent } from '@mui/material';
import {
    ResponsiveContainer,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Bar,
    Legend,
    BarChart,
} from 'recharts';
import { AutocompleteArrayInput, Button, Filter, Form, FormProps, ReferenceArrayInput, SaveButton, useTranslate } from 'react-admin';
import dataProvider from '../providers/dataProvider';
import subMonths from 'date-fns/subMonths';

const MonthTagBarChart = () => {
    const translate = useTranslate();
    const [tagIds, setTagIds] = useState<number[] | null>(null);
    const [tags, setTags] = useState<{ id: number; name: string; color: string; }[] | null | 'isLoading'>(null);
    const [barCharData, setBarCharData] = useState(null);

    const getStats = (tags: number[]) => {
        dataProvider.getStats("tag", {
            dateRange: {
                start: subMonths(new Date(), 1),
                end: new Date(),
            },
            tags: tags
        })
            .then((value: { data: any, tags: any }) => {
                setTags(value.tags);
                setBarCharData(value.data);
            })
            .catch((error) => { });
    };

    useEffect(() => {
        if (tags === null) {
            if (tags !== 'isLoading') {
                if (tagIds) {
                    getStats(tagIds);
                }
            }
        }

        return;
    }, [tagIds]);

    if (tags === undefined || tags === null || tags === 'isLoading' || tags.length === 0) {
        return (
            <Card sx={{ display: 'flex', flex: 1, mr: 1, flexDirection: 'column' }}>
                <Form onSubmit={(data: any) => {
                    setTags(null);
                    setTagIds(data.tag)
                }}>
                    <ReferenceArrayInput source="tag" reference="tag">
                        <AutocompleteArrayInput source="tag" />
                    </ReferenceArrayInput>
                    <Button type='submit' variant='contained' label='Apply' />
                </Form>
            </Card>
        );
    }

    return (
        <Card sx={{ display: 'flex', flex: 1, mr: 1, flexDirection: 'column' }}>
            <Form onSubmit={(data: any) => {
                setTags(null);
                setTagIds(data.tag)
            }}>
                <ReferenceArrayInput source="tag" reference="tag">
                    <AutocompleteArrayInput source="tag" />
                </ReferenceArrayInput>
                <Button type='submit' variant='contained' label='Apply' />
            </Form>
            <CardHeader title={translate('pos.dashboard.month_history_by_tag')} />
            <CardContent>
                <div style={{ width: '100%', height: 300 }}>
                    <ResponsiveContainer width="100%" height="100%">
                        <BarChart
                            width={500}
                            height={300}
                            data={barCharData || []}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="month" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            {tags.map((tag: any) => (
                                <Bar dataKey={tag.name} fill={tag.color} unit="лв." />
                            ))}
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </CardContent>
        </Card>
    );
};

export default MonthTagBarChart;