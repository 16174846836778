import { Admin, Resource } from 'react-admin';
import Dashboard from './Dashboard';
import authProvider from './providers/authProvider';
import dataProvider from './providers/dataProvider';
import i18nProvider from './providers/i18nProvider';
import budgets from './resources/budgets';
import companies from './resources/companies';
import establishments from './resources/establishments';
import family from './resources/family';
import familyMember from './resources/familyMember';
import paymentAccounts from './resources/paymentAccounts';
import tags from './resources/tags';
import transactionItems from './resources/transactionItems';
import transactions from './resources/transactions';

function App() {
  return (
    <Admin
      dashboard={Dashboard}
      authProvider={authProvider}
      dataProvider={dataProvider}
      i18nProvider={i18nProvider}
      disableTelemetry>
      <Resource key={`budget-resource`} {...budgets} />
      <Resource key={`payment-account-resource`} {...paymentAccounts} />
      <Resource key={`tag-resource`} {...tags} />
      <Resource key={`company-resource`} {...companies} />
      <Resource key={`establishment-resource`} {...establishments} />
      <Resource key={`transaction-resource`} {...transactions} />
      <Resource key={`transaction-item-resource`} {...transactionItems} />
      <Resource key={`family-resource`} {...family} />
      <Resource key={`family-member-resource`} {...familyMember} />
    </Admin>
  );
}

export default App;
