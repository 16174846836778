import Icon from '@mui/icons-material/LocalOffer';
import { useMediaQuery } from '@mui/material';
import { BooleanField, Datagrid, DeleteWithConfirmButton, EditButton, List, NumberField, SimpleList, TextField } from "react-admin";

const PaymentAccountList = () => {
    const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

    if (isSmall) {
        return (
            <List>
                <SimpleList
                    leftAvatar={() => <Icon />}
                    primaryText={record => record.name}
                    rowStyle={(record, index) => ({ backgroundColor: index % 2 === 0 ? '#efe' : 'white' })}
                    tertiaryText={record => <BooleanField source="isActive" />}
                />
            </List>
        );
    }

    return (
        <List>
            <Datagrid>
                <TextField source="id" />
                <TextField source="name" />
                <BooleanField source="isActive" />
                <BooleanField source="isDefault" />
                <TextField source="type" />
                <NumberField source="balance" options={{ style: 'currency', currency: 'BGN' }} />
                <EditButton />
                <DeleteWithConfirmButton />
            </Datagrid>
        </List>
    );
};

export default PaymentAccountList;