import EstablishmentIcon from "@mui/icons-material/Store";
import EstablishmentCreate from "./EstablishmentCreate";
import EstablishmentEdit from "./EstablishmentEdit";
import EstablishmentList from "./EstablishmentList";
import EstablishmentShow from "./EstablishmentShow";

export default {
  name: "establishment",
  recordRepresentation: "name",
  list: EstablishmentList,
  create: EstablishmentCreate,
  edit: EstablishmentEdit,
  show: EstablishmentShow,
  icon: EstablishmentIcon,
};
