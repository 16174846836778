import '@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.base-theme.react.css';
import '@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.material-theme.react.css';
import { useMediaQuery } from '@mui/material';
import { Datagrid, DateField, EditButton, List, SimpleList, TextField } from "react-admin";

const FamilyList = () => {
    const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

    if (isSmall) {
        return (
            <List>
                <SimpleList
                    primaryText={record => record.name}
                    secondaryText={record => (
                        <DateField label="From" source="startDate" />
                    )}
                />
            </List>
        );
    }

    return (
        <List>
            <Datagrid bulkActionButtons={<></>}>
                <TextField source="id" />
                <TextField source="name" />
                <DateField source="startDate" />
                <EditButton />
            </Datagrid>
        </List>
    );
};

export default FamilyList;