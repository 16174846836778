import Icon from '@mui/icons-material/LocalOffer';
import { useMediaQuery } from '@mui/material';
import { BooleanField, Datagrid, DeleteWithConfirmButton, EditButton, List, SimpleList, TextField } from "react-admin";
import { ColorField } from 'react-admin-color-picker';

const TagList = () => {
    const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

    if (isSmall) {
        return (
            <List>
                <SimpleList
                    leftAvatar={() => <Icon />}
                    primaryText={record => record.name}
                    rowStyle={(record, index) => ({ backgroundColor: index % 2 === 0 ? '#efe' : 'white' })}
                    tertiaryText={record => <BooleanField source="isActive" />}
                // secondaryText={record => (
                //     <TextField source="name" />
                // )}
                />
            </List>
        );
    }

    return (
        <List>
            <Datagrid>
                <TextField source="id" />
                <TextField source="name" />
                <BooleanField source="isActive" />
                <ColorField source="color" />
                <TextField source="iconName" />
                <TextField source="iconFont" />
                <EditButton />
                <DeleteWithConfirmButton />
            </Datagrid>
        </List>
    );
};

export default TagList;