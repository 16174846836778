import MoneyIcon from '@mui/icons-material/Money';
import { Chip, useMediaQuery } from '@mui/material';
import { ArrayField, Datagrid, DateField, DeleteWithConfirmButton, EditButton, FunctionField, List, NumberField, ReferenceField, SimpleList, SingleFieldList, TextField } from "react-admin";

const TransactionList = () => {
    const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

    if (isSmall) {
        return (
            <List >
                <SimpleList
                    leftAvatar={() => <MoneyIcon />}
                    primaryText={(
                        <>
                            <TextField source="mainEstablishment.name" />
                            <span> - </span>
                            <DateField source="timestamp" />
                        </>
                    )}
                    rowStyle={(record, index) => ({ backgroundColor: index % 2 === 0 ? '#efe' : 'white' })}
                    // tertiaryText={record => new Date(record.timestamp).toLocaleDateString()}
                    tertiaryText={(record) => (
                        <NumberField
                            source="totalAmount"
                            options={{
                                style: 'currency',
                                currency: 'BGN',
                                signDisplay: 'exceptZero',
                            }}
                            record={record} />
                    )}
                    secondaryText={record => {
                        return (
                            <>
                                <br />
                                <ArrayField source="transactionPaymentAccounts">
                                    <SingleFieldList>
                                        <FunctionField
                                            sx={{ mr: 1 }}
                                            render={(rr: any) => {
                                                return (
                                                    <Chip
                                                        label={`${rr.paymentAccount.name}: ${rr.amount}`} />
                                                );
                                            }} />
                                    </SingleFieldList>
                                </ArrayField><br />
                                <NumberField fullWidth source="transactionItems.length" label="Items count" /> item(s)
                            </>
                        );
                    }}
                />
            </List>
        );
    }

    return (
        <List sort={{ field: 'timestamp', order: 'DESC'}}>
            <Datagrid
                rowStyle={(record, index) => ({
                    // backgroundColor: record.type === "income" ? '#efe' : 'white',
                })}>
                <TextField source="id" />
                <ReferenceField source="mainEstablishment.id" reference="establishment">
                    <TextField source="name" />
                </ReferenceField>
                <ArrayField source="transactionPaymentAccounts">
                    <SingleFieldList>
                        <FunctionField
                            sx={{ mx: 1 }}
                            render={(rr: any) => {
                                return (
                                    <Chip
                                        label={(
                                            <>
                                                <TextField source="paymentAccount.name" />:
                                                <NumberField source="amount" sx={{ml: 1}} options={{style: 'currency', currency: 'BGN', signDisplay: 'exceptZero'}} />
                                            </>
                                        )}
                                        // label={`${rr.paymentAccount.name}: ${<NumberField source="amount" options={{style: 'currency', currency: 'BGN'}} />}${rr.amount}`} 
                                        />
                                );
                            }} />
                    </SingleFieldList>
                </ArrayField>
                <DateField source="timestamp" showTime={true} />
                <NumberField source="totalAmount" options={{ style: 'currency', currency: 'BGN' }} />
                <NumberField source="discount" />
                <NumberField source="transactionItems.length" label="Items count" />
                <EditButton />
                <DeleteWithConfirmButton />
            </Datagrid>
        </List>
    );
};

export default TransactionList;