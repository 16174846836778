import BudgetIcon from "@mui/icons-material/AccountBalanceWallet";
import BudgetCreate from "./BudgetCreate";
import BudgetEdit from "./BudgetEdit";
import BudgetList from "./BudgetList";
import BudgetShow from "./BudgetShow";

export default {
  name: "budget",
  recordRepresentation: "name",
  list: BudgetList,
  create: BudgetCreate,
  edit: BudgetEdit,
  show: BudgetShow,
  icon: BudgetIcon,
};
