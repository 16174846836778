import { Edit, SimpleForm } from "react-admin";
import TransactionTitle from "./TransactionTitle";

const TransactionEdit = () => (
    <Edit title={<TransactionTitle />}>
        <SimpleForm sx={{ mb: 10 }}>
            TBD
        </SimpleForm>
    </Edit>
);

export default TransactionEdit;