import { Card, Stack } from '@mui/material';
import { DateField, EditButton, Labeled, Loading, NumberField, Title, useGetOne, useRedirect } from "react-admin";
import { useParams } from 'react-router-dom';

const TransactionItemShow = () => {
    const { id } = useParams();
    const redirect = useRedirect();
    const { data, isLoading } = useGetOne(
        'TransactionItem',
        { id },
        { onError: () => redirect('/TransactionItem') }
    );
    if (isLoading) { return <Loading />; }
    return (
        <div>
            <Title title="TransactionItem Show" />
            <Card>
                <Stack spacing={1}>
                    <EditButton />
                    <Labeled label="Date">
                        <DateField source="datetime" record={data} showTime={true} />
                    </Labeled>
                    <Labeled label="Amount">
                        <NumberField source="amount" options={{ style: 'currency', currency: "BGN" }} />
                    </Labeled>
                    <EditButton />
                </Stack>
            </Card>
        </div>
    );
};

export default TransactionItemShow;