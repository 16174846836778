const authProvider = {
  login: ({ username, password }: { username: string; password: string }) => {
    // const request = new Request("http://localhost:8023/api/login_check", {
    const apiUrl =
      process.env.NODE_ENV === "development"
        ? "http://localhost:8023/api/login_check"
        : "http://family-budget.momchil-milev.info/api/login_check";
    const request = new Request(apiUrl, {
      method: "POST",
      body: JSON.stringify({ username, password }),
      headers: new Headers({ "Content-Type": "application/json" }),
    });
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((auth) => {
        localStorage.setItem("token", auth.token);
      })
      .catch(() => {
        throw new Error("Network error");
      });
  },
  getToken: () => localStorage.getItem("token"),
  logout: () => {
    localStorage.removeItem("token");
    return Promise.resolve();
  },
  checkAuth: () =>
    localStorage.getItem("token") ? Promise.resolve() : Promise.reject(),
  checkError: (error: any) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem("token");
      //   return Promise.reject({ redirectTo: "/" });
      return Promise.reject();
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },
  // getIdentity: () => {
  //   try {
  //     const { id, fullName, avatar } = JSON.parse(localStorage.getItem("token") || '');
  //     return Promise.resolve({ id, fullName, avatar });
  //   } catch (error) {
  //     return Promise.reject(error);
  //   }
  // },
  getPermissions: () => Promise.resolve(""),
};

export default authProvider;
