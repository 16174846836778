import { fetchUtils } from "react-admin";
import { stringify } from "query-string";
import authProvider from "./authProvider";

const apiUrl =
  process.env.NODE_ENV === "development"
    ? "http://localhost:8023/api"
    : "http://family-budget.momchil-milev.info/api";
// const apiUrl = 'http://family-budget.momchil-milev.info/api';
// const httpClient = fetchUtils.fetchJson;

const httpClient = (
  url: any,
  options: {
    method?: "POST" | "GET" | "PUT" | "PATCH" | "DELETE" | "OPTIONS";
    headers?: any;
    body?: any;
  } = {}
) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const token = localStorage.getItem("token");
  // options.headers.set('Authorization', `Bearer ${token.token}`);
  options.headers = new Headers({
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  });
  return fetchUtils.fetchJson(url, options);
};

export default {
  getList: async (resource: any, params: any) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify({ [field]: order }),
      offset: (page - 1) * perPage,
      limit: perPage,
      filter: JSON.stringify(params.filter),
    };
    const url = `${apiUrl}/${resource}/?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => {
      return {
        data: json.data,
        total: json.total,
        // parseInt(headers.get('content-range').split('/').pop(), 10),
      };
    });
  },

  getStats: async (type: string, params: any) => {
    const query = {
      dateRange: JSON.stringify(params.dateRange),
      tags: JSON.stringify(params.tags),
    };
    const url = `${apiUrl}/stats/${type}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => {
      return {
        data: json.data,
        tags: json.tags,
      };
    });
  },

  getEstablishmentStats: async (params: any) => {
    const query = {
      dateRange: JSON.stringify(params.dateRange),
      establishments: JSON.stringify(params.establishments),
    };
    const url = `${apiUrl}/stats/establishments?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => {
      return {
        data: json.data,
        establishments: json.establishments,
      };
    });
  },

  getOne: (resource: any, params: any) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
      data: json,
    })),

  getMany: (resource: any, params: any) => {
    const query = {
      filter: JSON.stringify({ ids: params.ids }),
    };
    const url = `${apiUrl}/${resource}/?${stringify(query)}`;
    return httpClient(url).then(({ json }) => ({ data: json.data }));
  },

  getManyReference: (resource: any, params: any) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${apiUrl}/${resource}/?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => ({
      data: json,
      total: 10,
      // headers
      // && headers.get('content-range')
      // && headers.get('content-range')?.split('/')
      // && headers.get('content-range')?.split('/').pop()
      // ? parseInt(headers.get('content-range').split('/').pop(), 10)
      // : 0,
    }));
  },

  create: async (resource: any, params: any) => {
    // console.log(params);
    if (params.receipts) {
      // File: {path: string; lastModified: number; lastModifiedData: Date; name: string; size: number; type: string; webkitRelativePath: string}
      // {receipts: {rawFile: File; src: string; title: string}[]}
      // todo handle uploaded images
    }
    return httpClient(`${apiUrl}/${resource}/`, {
      method: "POST",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id },
    }));
  },

  update: (resource: any, params: any) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "PATCH",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json })),

  updateMany: (resource: any, params: any) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: "PATCH",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },

  delete: (resource: any, params: any) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "DELETE",
    }).then(({ json }) => ({ data: json })),

  deleteMany: (resource: any, params: any) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${apiUrl}/${resource}/?${stringify(query)}`, {
      method: "DELETE",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json.data }));
  },
};
