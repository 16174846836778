import FamilyMemberIcon from "@mui/icons-material/FamilyRestroom";
import FamilyMemberCreate from "./FamilyMemberCreate";
import FamilyMemberEdit from "./FamilyMemberEdit";
import FamilyMemberList from "./FamilyMemberList";
import FamilyMemberShow from "./FamilyMemberShow";

export default {
  name: "family-member",
  recordRepresentation: "name",
  list: FamilyMemberList,
  create: FamilyMemberCreate,
  edit: FamilyMemberEdit,
  show: FamilyMemberShow,
  icon: FamilyMemberIcon,
};
