import TagIcon from "@mui/icons-material/Tag";
import TagCreate from "./TagCreate";
import TagEdit from "./TagEdit";
import TagList from "./TagList";
import TagShow from "./TagShow";

export default {
  name: "tag",
  recordRepresentation: "name",
  list: TagList,
  create: TagCreate,
  edit: TagEdit,
  show: TagShow,
  icon: TagIcon,
};
