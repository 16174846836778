import '@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.base-theme.react.css';
import '@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.material-theme.react.css';
import { Create, DateInput, SelectInput, SimpleForm, TextInput } from "react-admin";

const FamilyMemberCreate = (props: any) => {

    return (
        <Create {...props}>
            <SimpleForm sx={{ mb: 10 }}>
                <TextInput required source="firstName" />
                <TextInput source="middleName" />
                <TextInput required source="lastName" />
                <DateInput required source="dateOfBirth" />
                <SelectInput source="sex" choices={[{ id: 'male', name: 'Мъж' }, { id: 'female', name: 'Жена' }]} />
            </SimpleForm>
        </Create>
    );
}

export default FamilyMemberCreate;