import BusinessIcon from "@mui/icons-material/Business";
import CompanyCreate from "./CompanyCreate";
import CompanyEdit from "./CompanyEdit";
import CompanyList from "./CompanyList";
import CompanyShow from "./CompanyShow";

export default {
  name: "company",
  recordRepresentation: "name",
  list: CompanyList,
  create: CompanyCreate,
  edit: CompanyEdit,
  show: CompanyShow,
  icon: BusinessIcon,
};
