import '@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.base-theme.react.css';
import '@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.material-theme.react.css';
import { DateInput, Edit, SelectInput, SimpleForm, TextInput } from "react-admin";
import FamilyMemberTitle from './FamilyMemberTitle';

const FamilyMemberEdit = () => (
    <Edit title={<FamilyMemberTitle />}>
        <SimpleForm sx={{ mb: 10 }}>
            <TextInput disabled source="id" />
            <TextInput required source="firstName" />
            <TextInput source="middleName" />
            <TextInput required source="lastName" />
            <DateInput required source="dateOfBirth" />
            <SelectInput source="sex" choices={[{ id: 'male', name: 'Мъж' }, { id: 'female', name: 'Жена' }]} />
        </SimpleForm>
    </Edit>
);

export default FamilyMemberEdit;