import '@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.base-theme.react.css';
import '@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.material-theme.react.css';
import Icon from '@mui/icons-material/Store';
import { useMediaQuery } from '@mui/material';
import { BooleanField, Datagrid, DeleteWithConfirmButton, EditButton, List, SimpleList, TextField } from "react-admin";

const CompanyList = () => {
    const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

    if (isSmall) {
        return (
            <List>
                <SimpleList
                    leftAvatar={() => <Icon />}
                    primaryText={record => record.name}
                    rowStyle={(record, index) => ({ backgroundColor: index % 2 === 0 ? '#efe' : 'white' })}
                    tertiaryText={record => <BooleanField source="isActive" />}
                // secondaryText={record => (
                //     <BooleanField source="isActive" />
                // )}
                />
            </List>
        );
    }

    return (
        <List>
            <Datagrid>
                <TextField source="id" />
                <TextField source="name" />
                <BooleanField source="isActive" />
                <EditButton />
                <DeleteWithConfirmButton />
            </Datagrid>
        </List>
    );
};

export default CompanyList;