import { useEffect, useState } from 'react';
import { ArrayInput, NumberInput, RadioButtonGroupInput, ReferenceInput, SimpleFormIterator, required } from "react-admin";
import { useFormContext, useWatch } from 'react-hook-form';
import dataProvider from '../../providers/dataProvider';
import PaymentAccountAmountInput from './PaymentAccountAmountInput';

const PaymentAccountInput = (props: any) => {

    const transactionItems = useWatch({ name: 'transactionItems' });
    const { setValue } = useFormContext();

    let totalAmount = 0;
    transactionItems.map((item: any) => {
        totalAmount += ((+item.quantity || 0) * (+item.price || 0)) - (+item.discount || 0);
    });

    useEffect(() => {
        setValue('transactionPaymentAccounts.amount', totalAmount);
    }, [totalAmount]);

    const [defaultPaymentAccount, setDefaultPaymentAccount] = useState<undefined | 'isLoading' | number>(undefined);
    if (defaultPaymentAccount === undefined) {
        if (defaultPaymentAccount !== 'isLoading') {
            dataProvider.getList('payment-account', { pagination: { page: 1, perPage: 100 }, sort: { id: 'ASC' }, filter: { isDefault: true } })
                .then((response: any) => {
                    if (response.data.length > 0) {
                        setDefaultPaymentAccount(response.data[0].id)
                    }
                })
                .catch((error: any) => {

                });
        }
        return <></>;
    }

    return (
        <ArrayInput
            {...props}
            required
            fullWidth
            sx={{
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: 'red',
                borderRadius: 10,
                padding: 5,
            }}>
            <SimpleFormIterator fullWidth>
                <ReferenceInput
                    isRequired={true}
                    validate={required()}
                    source="paymentAccount"
                    reference="payment-account"
                    filter={{ isActive: true }}>
                    <RadioButtonGroupInput
                        required
                        defaultValue={defaultPaymentAccount !== undefined && defaultPaymentAccount !== 'isLoading' && defaultPaymentAccount} />
                </ReferenceInput>
                <PaymentAccountAmountInput
                    required
                    fullWidth
                    defaultValue={totalAmount}
                    source="amount" />
            </SimpleFormIterator>
        </ArrayInput>
    );
};

export default PaymentAccountInput;