import { BooleanInput, Edit, SimpleForm, TextInput } from "react-admin";
import { ColorInput } from 'react-admin-color-picker';
import TagTitle from './TagTitle';

const TagEdit = () => (
    <Edit title={<TagTitle />}>
        <SimpleForm sx={{ mb: 10 }}>
            <TextInput disabled source="id" />
            <TextInput required source="name" />
            <BooleanInput source="isActive" />
            <ColorInput source="color" />
            <TextInput source="iconName" />
            <TextInput source="iconFont" />
        </SimpleForm>
    </Edit>
);

export default TagEdit;