import '@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.base-theme.react.css';
import '@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.material-theme.react.css';
import { BooleanInput, Edit, NumberInput, ReferenceInput, SelectInput, SimpleForm, TextInput } from "react-admin";
import PaymentAccountTitle from './PaymentAccountTitle';

const PaymentAccountEdit = () => (
    <Edit title={<PaymentAccountTitle />}>
        <SimpleForm sx={{ mb: 10 }}>
            <TextInput disabled source="id" />
            <TextInput required source="name" />
            <ReferenceInput reference="budget" source="budget">
                <SelectInput />
            </ReferenceInput>
            <BooleanInput source="isActive" />
            <BooleanInput source="isDefault" />
            <TextInput source="type" />
            <NumberInput source="balance" />
        </SimpleForm>
    </Edit>
);

export default PaymentAccountEdit;