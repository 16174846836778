import '@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.base-theme.react.css';
import '@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.material-theme.react.css';
import { Card, Stack } from '@mui/material';
import { DateField, EditButton, Labeled, Loading, TextField, Title, useGetOne, useRedirect } from "react-admin";
import { useParams } from 'react-router-dom';

const FamilyShow = () => {
    const { id } = useParams();
    const redirect = useRedirect();
    const { data, isLoading } = useGetOne(
        'tag',
        { id },
        { onError: () => redirect('/family') }
    );
    if (isLoading) { return <Loading />; }
    return (
        <div>
            <Title title="Tag Show" />
            <Card>
                <Stack spacing={1}>
                    <EditButton />
                    <Labeled label="Name">
                        <TextField source="name" />
                    </Labeled>
                    <Labeled label="Date">
                        <DateField source="startDate" record={data} />
                    </Labeled>
                    <EditButton />
                </Stack>
            </Card>
        </div>
    );
};

export default FamilyShow;