import '@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.base-theme.react.css';
import '@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.material-theme.react.css';
import { DateInput, Edit, SimpleForm, TextInput } from "react-admin";
import FamilyTitle from './FamilyTitle';

const FamilyEdit = () => (
    <Edit title={<FamilyTitle />}>
        <SimpleForm sx={{ mb: 10 }}>
            <TextInput disabled source="id" />
            <TextInput required source="name" />
            <DateInput required source="startDate" />
        </SimpleForm>
    </Edit>
);

export default FamilyEdit;