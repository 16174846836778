import FamilyIcon from "@mui/icons-material/FamilyRestroom";
import FamilyCreate from "./FamilyCreate";
import FamilyEdit from "./FamilyEdit";
import FamilyList from "./FamilyList";
import FamilyShow from "./FamilyShow";

export default {
  name: "family",
  recordRepresentation: "name",
  list: FamilyList,
  create: FamilyCreate,
  edit: FamilyEdit,
  show: FamilyShow,
  icon: FamilyIcon,
};
