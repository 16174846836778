import '@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.base-theme.react.css';
import '@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.material-theme.react.css';
import { BooleanInput, Edit, SimpleForm, TextInput } from "react-admin";
import CompanyTitle from './CompanyTitle';

const CompanyEdit = () => (
    <Edit title={<CompanyTitle />}>
        <SimpleForm sx={{ mb: 10 }}>
            <TextInput disabled source="id" />
            <TextInput required source="name" />
            <BooleanInput source="isActive" />
        </SimpleForm>
    </Edit>
);

export default CompanyEdit;