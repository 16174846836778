import PaymentAccountIcon from "@mui/icons-material/CreditCard";
import PaymentAccountCreate from "./PaymentAccountCreate";
import PaymentAccountEdit from "./PaymentAccountEdit";
import PaymentAccountList from "./PaymentAccountList";
import PaymentAccountShow from "./PaymentAccountShow";

export default {
  name: "payment-account",
  recordRepresentation: "name",
  list: PaymentAccountList,
  create: PaymentAccountCreate,
  edit: PaymentAccountEdit,
  show: PaymentAccountShow,
  icon: PaymentAccountIcon,
};
